import React from 'react';
import './chip.scss';
import cn from 'classnames';


const Chip = (props: any) => {
    return <svg className={cn("chip-default", {[props.className]: !!props.className})} viewBox="0 0 41 41" version="1.1"
                xmlns="http://www.w3.org/2000/svg">
        <title>chip_ico</title>
        <g id="desktop_1440" stroke="none" strokeWidth="1" fillRule="evenodd">
            <polygon id="chip_ico"
                     points="33.3403429 0.785261257 33.3403429 5.31525985 28.0579602 5.31525985 28.0579602 0.785261257 23.2981962 0.785261257 23.2981962 5.31525985 18.0158135 5.31525985 18.0158135 0.785261257 13.25043 0.785261257 13.25043 5.31525985 7.97366684 5.31525985 7.97366684 0.785261257 0.657004855 0.785261257 0.657004855 8.09733095 5.18636704 8.09733095 5.18636704 13.3804559 0.657004855 13.3804559 0.657004855 18.1408886 5.18636704 18.1408886 5.18636704 23.4240135 0.657004855 23.4240135 0.657004855 28.1844463 5.18636704 28.1844463 5.18636704 33.4675712 0.657004855 33.4675712 0.657004855 40.7852613 7.97366684 40.7852613 7.97366684 36.260883 13.25043 36.260883 13.25043 40.7852613 18.0158135 40.7852613 18.0158135 36.260883 23.2981962 36.260883 23.2981962 40.7852613 28.0579602 40.7852613 28.0579602 36.260883 33.3403429 36.260883 33.3403429 40.7852613 40.6570049 40.7852613 40.6570049 33.4675712 36.1332622 33.4675712 36.1332622 28.1844463 40.6570049 28.1844463 40.6570049 23.4240135 36.1332622 23.4240135 36.1332622 18.1408886 40.6570049 18.1408886 40.6570049 13.3804559 36.1332622 13.3804559 36.1332622 8.09733095 40.6570049 8.09733095 40.6570049 0.785261257"/>
        </g>
    </svg>
};

export default Chip;