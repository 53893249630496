import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import Arrow from '../SVG/Arrow/Arrow';
import './slider.scss';
import {CSSTransition} from 'react-transition-group';
import cn from 'classnames';

const Slider = (props: any) => {

    const containerRef = useRef<HTMLDivElement>(null);
    const listRef = useRef<HTMLDivElement>(null);
    const [ts, setTs] = useState(0);
    const [te, steTe] = useState(0);
    let [current, setCurrent] = useState<number>(props.current);
    const [showUpArrow, setShowUpArrow] = useState<boolean>(props.current > 0);
    const [showDownArrow, setShowDownArrow] = useState<boolean>(false);

    const goNext = () => {
        if (current === 0) {
            setShowUpArrow(true);
        }
        if (current === props.items.length - 2) {
            setShowDownArrow(false);
        }
        if (current < props.items.length - 1) {
            setCurrent((current) => current + 1);
        }
    };

    const goPrev = () => {
        if (current === 1) {
            setShowUpArrow(false);
        }
        if (current === props.items.length - 1) {
            setShowDownArrow(true);
        }
        if (current !== 0) {
            setCurrent((current) => current - 1);
        }
    };

    const reset = () => {
        setCurrent(0);
        setShowUpArrow(false);
        window.scrollTo(0, 0);
    };

    const handleTouchStart = (e: any) => {
        setTs(e.changedTouches[0].clientY);
    };

    const handleTouchend = (e: any) => {
        setTimeout(() => {
            steTe(e.changedTouches[0].clientY);
        }, 200);
    };

    const renderItems = () => {
        return props.items.map((item: any, index: number) => {
            const style = index !== props.items.length - 1 && props.gap ? {marginBottom: `${props.gap}px`} : {};
            return <div key={`${index}-slider-item`}
                        style={style}
                        className="slider-item">
                {item}
            </div>
        });
    };

    useEffect(() => {
        if (props.current > 0) {
            setCurrent(props.current);
        }
    }, [props.current]);

    useEffect(() => {
        console.log('touch subscription');
        if (listRef && listRef.current && listRef.current.childNodes && listRef.current.childNodes.length) {
            listRef.current.childNodes.forEach((node) => {
                node.addEventListener('touchstart', handleTouchStart);
                node.addEventListener('touchend', handleTouchend);
            });

            return () => {
                if (listRef && listRef.current && listRef.current.childNodes && listRef.current.childNodes.length) {
                    listRef.current.childNodes.forEach((node) => {
                        node.removeEventListener('touchstart', handleTouchStart);
                        node.removeEventListener('touchend', handleTouchend);
                    });
                }
            }
        }

        // if (containerRef && containerRef.current) {
        //     containerRef.current.addEventListener('touchstart', handleTouchStart);
        //     containerRef.current.addEventListener('touchend', handleTouchend);
        //
        //     return () =>  {
        //         if (containerRef && containerRef.current) {
        //             containerRef.current.removeEventListener('touchstart', handleTouchStart);
        //             containerRef.current.removeEventListener('touchend', handleTouchend);
        //         }
        //     };
        // }
    // }, [containerRef, props.items.length]);
    }, [listRef, props.items.length]);

    useLayoutEffect(() => {
        if (listRef && listRef.current && listRef.current.childNodes && listRef.current.childNodes.length) {
            //@ts-ignore
            const currentHeight = listRef.current.childNodes[current].offsetHeight;
            const translateY = -current * (currentHeight + props.gap);
            //@ts-ignore
            listRef.current.style.transform = `translateY(${translateY}px)`;
        }
    }, [current, props.gap]);

    useEffect(() => {
        setShowDownArrow(true);

        window.addEventListener('resize', reset);

        return () => window.removeEventListener('resize', reset);
    }, []);

    useEffect(() => {
        if ((ts > te + 30) && (current < props.items.length - 1)) {
            goNext();
        }

        if ((ts < te - 30) && (current !== 0)) {
            goPrev()
        }
    }, [te]);

    return <div className="slider-container"
                ref={containerRef}>
        <CSSTransition in={showUpArrow}
                       classNames="up-arrow"
                       mountOnEnter={true}
                       unmountOnExit={true}
                       timeout={300}>
            <div key="hhjjk" className={cn("slider-nav-arrow", "arrow-up")} onClick={goPrev}><Arrow
                className="arrow-revers"/></div>
        </CSSTransition>
        <CSSTransition in={showDownArrow}
                       mountOnEnter={true}
                       unmountOnExit={true}
                       classNames="down-arrow"
                       timeout={300}>
            <div key="gfhffh" className={cn("slider-nav-arrow", "arrow-down")} onClick={goNext}><Arrow/></div>
        </CSSTransition>
        <div className="slider-items" ref={listRef}>
            {renderItems()}
        </div>

    </div>
}

export default Slider;
