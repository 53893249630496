import React, {useState} from 'react';
import {CSSTransition} from "react-transition-group";

export default function useButton(className: string, classNames: any,  onExited: any, renderContent: any, title: string = '') {
    const [show, setShow] = useState(false);

    return <div><CSSTransition in={show}
                            classNames= {classNames}
                            onEntered={() => {
                                setShow(false);
                            }}
                            onExited={onExited}
                            timeout={{
                                appear: 1000,
                                enter: 500,
                                exit: 2000,
                            }}>
        <button className={className} onClick={() => {setShow(true)}}>
            {(renderContent && typeof renderContent === 'function')
                ? <CSSTransition in={show} classNames='hide-item'
                                 timeout={2000}><>{renderContent()}</>
            </CSSTransition>
                : title}
        </button>
    </CSSTransition></div>
}