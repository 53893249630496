export enum homeLayout {
    FIRST = 1,
    SECOND = 2,
    THIRD = 3,
    FOURTH = 4
}

export enum layoutDirection {
    HORIZONTAL = 1,
    VERTICAL = 2
}

export enum language {
    ENGLISH ='en',
    FINLAND = 'fi',
    SWEDISH ='se'
}