import {makeAutoObservable} from 'mobx';

export default class RatingModalVM {

    rating: any = null;
    loading: boolean = true;
    selected: any = null;

    constructor() {
        makeAutoObservable(this);
        this.setSelected = this.setSelected.bind(this);
    }

    setCasinoRating(rating: any) {
        this.rating = rating;
    }

    setLoading(loading: boolean) {
        this.loading = loading;
    }

    resetCasinoRating() {
        this.rating = null;
    }

    setSelected(selected: any) {
        this.selected = selected;
    }
}

