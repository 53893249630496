import React, {FC, useEffect, useState} from 'react';
import cn from 'classnames';
import InsaiderHead from "../SVG/InsaiderHead/InsaiderHead";
import InsaiderBody from "../SVG/InsaiderBody/InsaiderBody";
import {CSSTransition} from "react-transition-group";
import './loader.scss';

type TLoaderProps = {
    className?: string,
    onLoad?: () => void,
    startTitle?: string,
    endTitle?: string

};

const Loader: FC<TLoaderProps> = (props: TLoaderProps) => {

    let [start, setStart] = useState(false);
    let [showStartTitle, setShowStartTitle] = useState(false);
    let [showEndTitle, setShowEndTitle] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setStart(true);
            setShowStartTitle(true);
        }, 500);
    }, []);


    const onEnterDoneCallback = () => {
        setTimeout(() => {
            setStart(false);
        }, 3000);
    }

    return <div className={cn('loader', props.className ? props.className : '')}>
        <div className="mark">
            <CSSTransition in={start}
                           mountOnEnter={true}
                           timeout={{
                               enter: 2000,
                               appear: 2000,
                               exit: 2000,
                           }}
                           onEntered={onEnterDoneCallback}
                           onExited={props.onLoad}
                           classNames={{
                               enter: 'mark-head-enter',
                               enterActive: 'mark-head-enter-active',
                               enterDone: 'mark-head-enter-done',
                               exit: 'mark-head-exit',
                               exitActive: 'mark-head-exit-active',
                               exitDone: 'mark-head-exit-done',
                           }}>
                <div>
                    <InsaiderHead className={''}/>
                    <div className="circle">{null}</div>
                </div>
            </CSSTransition>
            <CSSTransition in={start}
                           timeout={2000}
                           mountOnEnter={true}
                           classNames={{
                               enter: 'mark-body-enter',
                               enterActive: 'mark-body-enter-active',
                               enterDone: 'mark-body-enter-done',
                           }}>
                <InsaiderBody className="mark-body"/>
            </CSSTransition>
        </div>
        <div className="loader-title">
            {props.startTitle ? <CSSTransition in={showStartTitle}
                           timeout={1500}
                           mountOnEnter={true}
                           unmountOnExit={true}
                           onEntered={() => {setShowStartTitle(false)}}
                           onExited={() => {setShowEndTitle(true)}}
                           classNames={{
                               enter: 'left-slide-enter',
                               enterActive: 'left-slide-enter-active',
                               enterDone: 'left-slide-enter-done',
                               exit: 'left-slide-exit',
                               exitActive: 'left-slide-exit-active',
                               exitDone: 'left-slide-exit-done'
                           }}>
                <div className="loader-start-title">{props.startTitle}</div>
            </CSSTransition> : null}
            { props.endTitle ?
                <CSSTransition in={showEndTitle}
                               timeout={1500}
                               mountOnEnter={true}
                               classNames={{
                                   enter: 'left-slide-enter',
                                   enterActive: 'left-slide-enter-active',
                                   enterDone: 'left-slide-enter-done'
                               }}>
                <div className="loader-end-title">{props.endTitle}</div>
                </CSSTransition>
                : null

            }

        </div>
    </div>
};

export default Loader;