import React from 'react';
import Card from './Card/Card';
import './cards.scss';
import Chart from "../../../SVG/Chart/Chart";
import Chip from '../../../SVG/Chip/Chip';
import Crown from '../../../SVG/Crown/Crown';
import Dice from '../../../SVG/Dice/Dice';
import getTranslation from "../../../../helpers/getTranslation";

const Cards = () => {

    return <div className="cards-container">
        <div className="cards-row">
            <Card icon={<Chip className="flicker"/>}
                  text={getTranslation('chipText')}/>
            <Card icon={<Chart className="flicker"/>}
                  text={getTranslation('chartText')}/>
        </div>
        <div className="cards-row">
            <Card icon={<Crown className="flicker"/>}
                  text={getTranslation('crownText')}/>
            <Card icon={<Dice className="flicker"/>}
                  text={getTranslation('diceText')}/>
        </div>
    </div>
}

export default Cards;