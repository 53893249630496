import React from 'react';
import './basic.scss';
import InsaiderLogo from '../../../SVG/InsaiderLogo/InsaiderLogo';
import {layoutDirection} from '../constants';
import CopyAndTerms from '../../../CopyAndTerms/CopyAndTerms';
import SMSQLogo from '../../../SVG/SMSQLogo/SMSQLogo';
import {useHistory} from 'react-router-dom';
import useButton from '../../../../hooks/useButton';
import getTranslation from '../../../../helpers/getTranslation';
import AppStore from '../../../../AppStore';
import LanguageSwitch from '../../../LanguageSwitch/LanguageSwitch';

const cn = require('classnames');
const Basic = (props: any) => {
    const history = useHistory();
    const toResults = () => {

        let path = `/${AppStore.locale}/results`;

        history.push(path);
    };
    const renderContent = () => <span>{getTranslation('buttonText')}s</span>;
    const classNames = {
        appear: '',
        appearActive: '',
        appearDone: '',
        enter: 'btn-loader-enter',
        enterActive: 'btn-loader-enter-active',
        enterDone: 'btn-loader-enter-done',
        exit: 'btn-loader-exit',
        exitActive: 'btn-loader-exit-active',
        exitDone: 'btn-loader-exit-done',
    };

    const generateButton = useButton('btn btn_green btn-big', classNames, toResults, renderContent);

    return <div className="basic-container">
        <div
            className={cn('basic-content', {'basic-container_horizontal': props.direction === layoutDirection.HORIZONTAL}, {'basic-container_vertical': props.direction === layoutDirection.VERTICAL})}>
            <div className="basic-logo">
                <InsaiderLogo className="logo-svg flicker"/>
            </div>
            <div
                className={cn("basic-description", {'basic-description_margin': props.direction === layoutDirection.VERTICAL})}>
                <p className={cn("main-text", "slide-down", {'main-text_horizontal': props.direction === layoutDirection.HORIZONTAL})}>
                    {getTranslation('mainText')}</p>
                {generateButton}
                <div
                    className={cn('sub-text', 'slide-down', {'sub-text_horizontal': props.direction === layoutDirection.HORIZONTAL})}>
                    <div>{getTranslation('subText')}</div>
                </div>
            </div>


        </div>
        <div className="basic-footer">
            <LanguageSwitch
                className="language"
                items={AppStore.languages}
                lng={AppStore.locale}/>
            <CopyAndTerms/>
            {/*<SMSQLogo className="smsq-logo-small flicker"/>*/}
        </div>
    </div>
}

export default Basic;