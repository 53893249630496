import React, { FC, SyntheticEvent } from 'react';
import Modal from '../../../Modal/Modal';
import './reviewModal.scss';
import Close from '../../../SVG/Close/Close';

type TReviewModalProps = {
    name: string;
    onClose(e: SyntheticEvent): void;
};

const ReviewModal:FC<TReviewModalProps> = (props: TReviewModalProps) => {

    return <Modal>

        <div className="review-modal-container">
            <header className="review-modal-header">
                <div className="review-modal-logo">{null}</div>
                <h1 className="review-modal-title">{props.name}</h1>
                <button className="review-modal-close-btn" onClick={props.onClose}>
                    <Close className="review-modal-close-ico"/>
                </button>
            </header>
            <div className="review-modal-description">
                <p>A detailed description of this casino with important accents and benefits for players.</p>
            </div>
            <footer className="review-modal-footer">
            <div className="review-modal-generations-count">get +1 more <a href="/">generations</a> today</div>
            <a className="review-modal-casino-link" href="/"><span>Play Now</span></a>
            </footer>
        </div>
    </Modal>
};

export default ReviewModal;