import React, {useState} from 'react';
import getTranslation from '../../helpers/getTranslation';
import * as s from './cookieBar.module.scss';
import {AGREE_COOKIE_KEY} from './constants';

const CookieBar = () => {

    const isAgree = Boolean(window.localStorage.getItem(AGREE_COOKIE_KEY));
    const [hasCookieAgree, setHasCookieAgree] = useState<boolean>(isAgree);

    const setCookiesAgree = () => {
        window.localStorage.setItem(AGREE_COOKIE_KEY, 'true');
        setHasCookieAgree(true);
    };

    if (hasCookieAgree) {
        return null;
    }

    //@ts-ignore
    return <div className={s.Wrapper}>
        {/*@ts-ignore*/}
        <p className={s.Text}>{getTranslation('cookieText')}</p>
        {/*@ts-ignore*/}
        <button onClick={setCookiesAgree} className={s.Button}>{getTranslation('cookieButton')}</button>
    </div>
}

export default CookieBar;