import React from "react";
import { makeAutoObservable, toJS } from "mobx";
import ReviewModal from "../Components/Views/Results/ReviewModal/ReviewModal";

export type ResultModal = {
    type: string,
    id?: number,
    url?: string
};

export default class ResultsStore {

    public items: any[] = [];
    public selected: any;
    public loading: boolean = false;
    public modal: ResultModal | null = null;
    
    constructor() {
        makeAutoObservable(this);
        this.setSelected= this.setSelected.bind(this);
        this.resetSelected = this.resetSelected.bind(this);
        this.setModal = this.setModal.bind(this);
    }

    setSelected(id: number) {
        const items = toJS(this.items);
        
        this.selected = items.find((item)=>{
               return item.id === id;
        });
    }

    setItems (items: any[]) {
        this.items = items;
    }

    resetSelected() {
        this.selected = null;
    }

    setLoading(loading: boolean) {
        this.loading = loading;
    }

    setModal(modal: ResultModal | null) {
        this.modal = modal;
    }

    get reviewModal () {
        if (!this.selected) {
            return null;
        }

        return <ReviewModal onClose={this.resetSelected} name={this.selected.name}/>
    }
}