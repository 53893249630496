import React from 'react';
import './insaiderBody.scss';
import cn from 'classnames';


const InsaiderBody = ({className}: {className?: string}) => {
   return <svg  viewBox="0 0 55 41" version="1.1" xmlns="http://www.w3.org/2000/svg" className={cn('insaider-body-default', className? className: '')}>
       <title>mark_ico-path</title>
       <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
           <polygon id="mark_ico-path"  fillRule="nonzero" points="19.4879497 0.3538459 21.1631091 23.4955983 33.6711146 23.4955983 35.3485016 0.3538459 38.2867134 0.3538459 54.0848923 34.3845386 38.6854548 40.7852613 35.3485016 33.9240391 19.4879497 33.9240391 16.1509965 40.7852613 0.751558985 34.3845386 16.5475103 0.3538459" />
       </g>
   </svg>
}

export default InsaiderBody;