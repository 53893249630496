import React from 'react';
import './crown.scss'
import cn from 'classnames';

const Crown = (props: any) => {
    return <svg className={cn("crown-default", {[props.className]: props.className})} viewBox="0 0 41 41" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>crown_ico</title>
        <g id="desktop_1440" stroke="none" strokeWidth="1" fillRule="evenodd">
            <path d="M20.3242587,37.1892506 C11.4522261,37.1892506 4.26020474,35.5541895 4.26020474,33.542671 C4.26020474,31.5255338 11.4522261,29.8904727 20.3242587,29.8904727 C29.1962913,29.8904727 36.3883126,31.5255338 36.3883126,33.542671 C36.3883126,35.5541895 29.1962913,37.1892506 20.3242587,37.1892506 M39.7314788,3.8643454 C39.3999716,3.729495 39.124652,3.8306328 38.692007,4.1340462 L29.9941561,11.3092112 L20.9928919,1.15609986 C20.8130914,0.91449289 20.5658657,0.785261257 20.3242587,0.785261257 C20.0770329,0.785261257 19.835426,0.91449289 19.6500067,1.15609986 L10.6487425,11.3092112 L1.95089164,4.1340462 C1.5182466,3.8306328 1.24292704,3.729495 0.917038568,3.8643454 C0.5574375,4.06100223 0.321449299,4.5217411 0.321449299,5.02181133 L0.321449299,34.2787294 C0.321449299,39.4142822 12.8962491,40.7852613 20.3242587,40.7852613 C27.7466495,40.7852613 40.3214493,39.4142822 40.3214493,34.2787294 L40.3214493,5.02181133 C40.3214493,4.5217411 40.0910799,4.06100223 39.7314788,3.8643454" id="crown_ico" fill="#000000" />
        </g>
    </svg>
}

export default Crown;