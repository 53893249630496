import { makeAutoObservable, toJS } from 'mobx';

export type TFilterItem = {
    id: number,
    name: string,
    selected: boolean,
    disabled: boolean,
    order: number

}
export default class FiltersVM {

    public filters: TFilterItem[] = []

    constructor () {
        makeAutoObservable(this);
    }

   public toggleSelected (id: number) {
        this.filters.forEach((filter: any, index: number)=> {
            if (filter.id === id) {
                this.filters[index].selected = !this.filters[index].selected;
            }
        });
    }

    get hasSelected () {

        return this.filters.some((filter: any) => {
               return filter.selected 
        });
    }

    get selected() {
        let count = 0;
        this.filters.forEach((filter: any)=> {
            if (filter.selected) count++;
        });

        return count;
    }

    get total () {
        return toJS(this.filters).length;
    }

    setFilters (filters: TFilterItem[]) {
        this.filters = filters;
    }
}

