import React from 'react';
import './dice.scss'
import cn from 'classnames';

const Dice = (props: any) => {
    return <svg className={cn("dice-default", {[props.className]: !!props.className})} viewBox="0 0 41 41" version="1.1"
                xmlns="http://www.w3.org/2000/svg">
        <title>dice_ico</title>
        <g id="desktop_1440" stroke="none" strokeWidth="1" fillRule="evenodd">
            <path
                d="M30.4998021,14.8248847 C28.5385771,14.8248847 26.9482427,13.2343269 26.9482427,11.2672059 C26.9482427,9.31132561 28.5385771,7.71514744 30.4998021,7.71514744 C32.4666468,7.71514744 34.0513616,9.31132561 34.0513616,11.2672059 C34.0513616,13.2343269 32.4666468,14.8248847 30.4998021,14.8248847 M20.9915133,24.3345095 C19.0246687,24.3345095 17.4343343,22.7439517 17.4343343,20.7880714 C17.4343343,18.8209505 19.0246687,17.236013 20.9915133,17.236013 C22.9471188,17.236013 24.5374532,18.8209505 24.5374532,20.7880714 C24.5374532,22.7439517 22.9471188,24.3345095 20.9915133,24.3345095 M11.4719853,33.8553751 C9.51637984,33.8553751 7.92042592,32.2648172 7.92042592,30.2976963 C7.92042592,28.3361956 9.51637984,26.7456378 11.4719853,26.7456378 C13.43883,26.7456378 15.0291643,28.3361956 15.0291643,30.2976963 C15.0291643,32.2648172 13.43883,33.8553751 11.4719853,33.8553751 M33.3826344,0.785261257 L8.59477264,0.785261257 C4.39134471,0.785261257 0.985893744,4.18557038 0.985893744,8.38958892 L0.985893744,33.1809336 C0.985893744,37.3849521 4.39134471,40.7852613 8.59477264,40.7852613 L33.3826344,40.7852613 C37.5804428,40.7852613 40.9858937,37.3849521 40.9858937,33.1809336 L40.9858937,8.38958892 C40.9858937,4.18557038 37.5804428,0.785261257 33.3826344,0.785261257"
                id="dice_ico" fill="#000000"/>
        </g>
    </svg>
};

export default Dice;