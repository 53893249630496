import React, {useState} from 'react';
import './languageSwitch.scss';
import useButton from "../../hooks/useButton";
import LanguageSwitchModal from "./LanguageSwitchModal";
import {toJS} from "mobx";

interface ILanguageSwitchProps {
    className?: string;
    items: object;
    lng: string;
    options?: string[]
}

const LanguageSwitch = (props: ILanguageSwitchProps) => {
    let [showModal, setShowModal] = useState(false);
    const classNames = {
        enter: 'language-switch-enter',
        enterActive: 'language-switch-active',
        enterDone: 'language-switch-enter-done',
        exit: 'language-switch-exit',
        exitActive: 'language-switch-exit-active',
        exitDone: '',
    };

    const renderContent = () => <span>{props.lng}</span>;
    const generateButton = useButton('language-switch', classNames, () => {
        setShowModal(true)
    }, renderContent);

    return <>{generateButton}
        {showModal && <LanguageSwitchModal
            items={toJS(props.items)}
            options={props.options}
            lng={props.lng}
            onClose={()=> {setShowModal(false)}}/>}
    </>
};

export default LanguageSwitch;

