import React from "react";
//@ts-ignore
import './winning.scss';
import getTranslation from "../../../../helpers/getTranslation";

const Winning = () => {
    return <div className="winning-container">
        <h1 className="winning-title flicker">
            {getTranslation('winTitle')}
        </h1>
        <p className="winning-text slide-down">{getTranslation('winText')}</p>
    </div>
}

export default Winning;