export default function parseCasinoResp(resp: any) {
    if (!resp || !resp.data || !resp.data.data || !resp.data.data.items || !resp.data.data.items.length) {
        return [];
    }

    return resp.data.data.items.map((item: any) => {
        const description = item.description.replace(/<\/?div>/ig, '');
        const descriptionShort = item.descriptionShort.replace(/<\/?div>/ig, '');

        return {
            id: item.id,
            name: item.name,
            logo: item.logo,
            rating: item.rating.overall,
            title: descriptionShort,
            text: description,
            url: item.url
        }
    });
}