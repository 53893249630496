import React, {useState} from 'react';
import './filterModal.scss';
import Close from "../../../SVG/Close/Close";
import Checkbox from '../../../CheckBox/CheckBox';
import {observer} from 'mobx-react';
import appStore from '../../../../AppStore';
import Filter from '../../../Filter/Filter';
import Modal from "../../../Modal/Modal";
import getTranslation from "../../../../helpers/getTranslation";

const FiltersModal = (props: any) => {

    const handleFilterClick = (id: number) => {
        return () => {
            appStore.filterStore.toggleSelected(id)
        }
    }

    const renderFilters = () => {
        return appStore.filterStore.filters.map((filter: any) => {
            return <Filter key={`filter-${filter.id}`} {...filter} onClick={handleFilterClick(filter.id)}/>
        });
    };

    // let [showHints, setShowHints] = useState(true);
    // const onCheckBoxClick = () => {
    //     setShowHints(!showHints);
    // }

    return <Modal>
        <div className="filters-modal-container">
            <header className="filters-modal-header">
                <h1 className="filters-modal-tittle flicker">{getTranslation('filtersModalTitle')}</h1>
                <button className="filters-modal-close-btn" onClick={props.onClose}><Close
                    className="filters-modal-close-ico fade"/></button>
            </header>
            <div className="filters-modal-content">
                <p className="filters-modal-text slide-down">{getTranslation('filtersModalText')}
                </p>
                <div className="filters-modal-filters-list fade">
                    {renderFilters()}
                </div>
            </div>
            <footer className="filters-modal-footer">
                {/*<Checkbox title={getTranslation('showHintsText')} checked={showHints} onClick={onCheckBoxClick}*/}
                {/*          className="filters-modal-checkbox"/>*/}
                <div
                    className="filters-modal-filter-counter slide-down">{getTranslation('selected')} {appStore.filterStore.selected} of {appStore.filterStore.total}</div>
                    <button className="filters-modal-apply-btn slide-down" onClick={props.onApply}>
                        <span>{getTranslation('applyBtnText')}</span>
                    </button>
            </footer>
        </div>
    </Modal>
}

export default observer(FiltersModal);