import React from "react";
import cn from "classnames";
import "./close.scss";

const Close = (props: any) => {
    return <svg className={cn("close-default", {[props.className]: props.className})} viewBox="0 0 19 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>close_ico</title>
    <g id="desktop_1440" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M10.9269513,9.78030046 L18.4071906,2.28651314 C18.736707,1.95578476 18.7438266,1.40932038 18.4071906,1.02567559 C18.0888606,0.706142004 17.4694902,0.704106804 17.1460758,1.02567559 L9.66888811,8.54592171 L2.1611892,1.02567559 C1.85811481,0.719370804 1.23874563,0.718353204 0.929569245,1.02465799 C0.753622851,1.20070759 0.657004855,1.42967358 0.657004855,1.66983197 C0.657004855,1.93034476 0.772946451,2.17355715 0.928551645,2.28651314 L8.40879096,9.78030046 L0.967198843,17.2669646 C0.773964051,17.412485 0.657004855,17.6556974 0.657004855,17.9172278 C0.657004855,18.1787569 0.773964051,18.4219693 0.929569245,18.5339077 C1.09737844,18.7018153 1.32824403,18.7852616 1.55910842,18.7852616 C1.79099161,18.7852616 2.0208396,18.7018153 2.1896664,18.5339077 L9.66888811,11.041138 L17.1470922,18.5349253 C17.4552522,18.8391949 18.1092006,18.8066305 18.3410838,18.5674897 C18.5343186,18.4219693 18.6512766,18.1787569 18.6512766,17.9172278 C18.6512766,17.666891 18.5444886,17.4338558 18.3644754,17.2863002 L10.9269513,9.78030046 Z" id="close_ico"  fillRule="nonzero"/>
    </g>
</svg>
};

export default Close;