import React from 'react';
import Modal from '../Modal/Modal';
import './languageSwitch.scss';
import getTranslation from '../../helpers/getTranslation';
import Close from '../SVG/Close/Close';
import cn from 'classnames';

const LanguageSwitchModal = (props: any) => {

    const renderItems = () => {

        const items = [];
        for (let key in props.items) {
            const className = cn('language-switch-modal-item', {'active': key === props.lng});
            let path = `/${key}`;
            if (props.options) {
                path = path + '/' + props.options.join('/');
            }
            items.push(<a key={key} onClick={props.onClose} href={path} className={className}>{props.items[key]}</a>);
        }

        return items;
    };

    return <Modal>
        <div className="language-switch-modal">
            <header className="language-switch-modal-header">
                <h1 className="language-switch-modal-title flicker">{getTranslation('languageModalTitle')}</h1>
                <button className="language-switch-modal-close-btn" onClick={props.onClose}><Close
                    className="language-switch-modal-close-ico fade"/></button>
            </header>
            <div>
                <p className="language-switch-modal-subtitle slide-down">{getTranslation('languageModalText')}</p>
                <div className="language-switch-modal-items fade">
                    {renderItems()}
                </div>
            </div>
            <div/>
        </div>
    </Modal>
};

export default LanguageSwitchModal;