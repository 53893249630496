import React from 'react';
import './star.scss'

const cn = require('classnames');
const Star = (props: any) => {
    return <svg className={cn('star-default', {[props.className]: props.className})}
                viewBox="0 0 43 41"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg">
        <g id="desktop_1440"
           stroke="none"
           strokeWidth="1"
           fill="none"
           fillRule="evenodd">
            <path
                d="M42.2488023,15.9779899 C42.1066653,15.5284404 41.7208651,15.2066039 41.2639963,15.1401932 L28.3498401,13.250042 L22.5729887,1.46980249 C22.3648597,1.05090412 21.9384488,0.785261257 21.4765038,0.785261257 C21.0094824,0.785261257 20.5830716,1.05090412 20.3800188,1.46980249 L14.6082438,13.250042 L1.68901124,15.1401932 C1.22706618,15.2066039 0.846342239,15.5284404 0.69912898,15.9779899 C0.55699204,16.4224308 0.683900022,16.9128484 1.01386078,17.234685 L10.3593646,26.4044725 L8.15116568,39.3494538 C8.07502089,39.8092203 8.2628447,40.2740953 8.63849233,40.5550637 C8.85169774,40.7083192 9.1055137,40.7852613 9.35932967,40.7852613 C9.5522298,40.7852613 9.74512993,40.7389703 9.92280111,40.6419085 L21.4765038,34.5321225 L33.0302065,40.6419085 C33.4413883,40.8615747 33.9388676,40.8258151 34.3145152,40.5550637 C34.6901629,40.2740953 34.8779867,39.8092203 34.7967656,39.3494538 L32.593643,26.4044725 L41.9391468,17.234685 C42.2741839,16.9128484 42.3909392,16.4224308 42.2488023,15.9779899"
                id="star_ico"/>
        </g>
    </svg>
};

export default Star;