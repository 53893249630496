import React from 'react';
import './filter.scss';
import cn from 'classnames';

export type TFilter = {
    name: string,
    onClick: any,
    order?: number,
    selected?: boolean,
    className?: string,
    disabled? : boolean
}

const Filter = (props: TFilter) => {

    return <button className={cn('result-filter-btn', {'selected': props.selected})} onClick={props.onClick}>
        {props.order && props.selected ? <span className="result-filter-order">{props.order}</span>  : null}
        <span className="result-filter-name">{props.name}</span>
    </button>
};

export default Filter;