import axios from "axios";
import {API_URL_BASE, CASINOS_SLUG, DEFAULT_LANGUAGE} from './constants';

type TGetCasinosParams = {
    limit?: number,
    offset?: number,
    filters?: number[] | string,
    sort?: [string, 'asc'| 'desc'],
    urlPart?: string,
}
export async function getCasinosListApi (params: TGetCasinosParams, lng: string = DEFAULT_LANGUAGE) {
    return await axios.get(`${API_URL_BASE}${lng}/${CASINOS_SLUG}`, {params});
}