import React from 'react';
import RatingModal from "../Results/RatingModal/RatingModal";
//@ts-ignore
import DocumentMeta from 'react-document-meta';

const Rating = () => {
    const meta = {
        title: 'AI rating explained | Insaider',
        description: 'Find out more about the Insaider online casino evaluation model.',
    };

    return <>
        <DocumentMeta {...meta} />
        <RatingModal/>
    </>
};

export default Rating;