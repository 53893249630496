import React from 'react';
import RatingLabel from '../RatingLabel/RatingLabel';
import './resultCard.scss';
import {useHistory} from 'react-router-dom';
import getTranslation from '../../../../helpers/getTranslation';
import AppStore from '../../../../AppStore';

export type TResultCard = {
    id: number,
    logo: string,
    name: string,
    rating: number,
    valid?: string
    title: string,
    text: string,
    url: string,
    reviewHandler: any,
}
const cn = require('classnames');
const ResultCard = (props: TResultCard) => {
    const history = useHistory();
    const onRatingClick = () => {
        history.push(`/${AppStore.locale}/rating/${props.id}`);
    }
    return <div className="result-card">
        <header className="result-card-header">
            <figure className="casino-logo slide-down">
                <img src={props.logo} alt={props.logo}/>
            </figure>
            <h3 className="casino-name flicker">{props.name}</h3>
            <RatingLabel value={props.rating} id={props.id} onClick={onRatingClick}/>
        </header>
        <div className="result-card-content">
            {props.valid ? <div className="result-valid">{props.valid}</div> : null}
            <h2 className="casino-title flicker">
                <a href={props.url}
                   target="_blank"
                   rel="noopener noreferrer">{props.title}</a>
            </h2>
            <p className="casino-text slide-down">{props.text}</p>
        </div>
        <footer className="result-card-footer">
            <a href={props.url}
               target="_blank"
               rel="noopener noreferrer"
               className={cn('btn', 'btn_green', 'btn-big', 'play-btn')}>
                <span>{getTranslation('playBtnText')}</span>
            </a>
            <div className="result-card-disclaimer">18+,
                &nbsp;<a href={getTranslation('disclaimerLink')} target="_blank" rel="noopener noreferrer">{getTranslation('disclaimerLinkText')}</a>&nbsp;
                {getTranslation('disclaimerText')}
            </div>
        </footer>
    </div>
};

export default ResultCard;