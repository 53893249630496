import React, {useEffect, useRef} from 'react';
import Close from "../../../SVG/Close/Close";
import RatingItem from "./RatingItem";
import cn from "classnames";
import Modal from "../../../Modal/Modal";
import getTranslation from "../../../../helpers/getTranslation";

type TItemSidebarProps = {
    id: number,
    name: string,
    type: string,
    value: number,
    values: {
        min: number,
        max: number
    }
    onClose: () => void;
    description: string;
}

const ItemSidebar = (props: TItemSidebarProps) => {

    const containerRef = useRef(null);

    const renderValues = () => {
        let count = 0;
        let items = [];
        switch (props.type) {
            case 'TRIPLE':
                count = 3;
                for (let i = 0; i < count; i++) {
                    let name = '';
                    switch (i) {
                        case 0:
                            name = `${getTranslation('ratingExplainerLow')}: <${props.values.min}`;
                            break;

                        case 1:
                            name = `${getTranslation('ratingExplainerMed')}: ${getTranslation('ratingExplainerBetween')} ${props.values.min} ${getTranslation('ratingExplainerAnd')} ${props.values.max}`;
                            break;

                        case 2:
                            name = `${getTranslation('ratingExplainerHigh')}: >${props.values.max}`;
                            break;
                    }

                    items.push(<RatingItem
                        value={i + 1}
                        name={name}
                        className={cn('item-sidebar-value', {'active': (i + 1) === props.value})}
                        key={`rating-item-${i}-${props.id}`}
                        onClick={() => false}/>);
                }
                break;

            case 'DOUBLE':
                count = 2;
                for (let i = 0; i < count; i++) {
                    let name = '';
                    let value = 0;
                    let active = false;
                    switch (i) {
                        case 0:
                            name = `${getTranslation('ratingExplainerNo')}`;
                            value = 1
                            active = props.value === 0;
                            break;

                        case 1:
                            name = `${getTranslation('ratingExplainerYes')}`;
                            value = 3;
                            active = props.value === 1;
                            break;
                    }

                    items.push(<RatingItem
                        value={value}
                        name={name}
                        className={cn('item-sidebar-value', {'active': active})}
                        key={`rating-item-${i}-${props.id}`}
                        onClick={() => false}/>);
                }

                break;
            default:
                count = 0;
        }

        if (!count) {
            return null;
        }

        return items;
    };

    const createMarkup = () => {
        return {__html: props.description};
    };


    const handleOutsideClick = (ev: any) => {
        //@ts-ignore
        if (containerRef && containerRef.current && !(containerRef.current.contains(ev.target) || ev.target === containerRef)) {
            props.onClose();
        }
    };
    useEffect(() => {

        window.addEventListener('click', handleOutsideClick);

        return () => window.removeEventListener('click', handleOutsideClick);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return <Modal>
        <div className="item-sidebar-container" ref={containerRef}>
            <div className="item-sidebar-header flicker">
                <div className="item-sidebar-title">
                    <h3>{getTranslation('ratingExplainerModalTitle')}</h3>
                </div>
                <button className="item-sidebar-close-btn" onClick={props.onClose}><Close
                    className="item-sidebar-close-ico fade"/></button>
            </div>

            <h4 className="item-sidebar-name">{props.name}</h4>

            <div className="item-sidebar-values">
                {renderValues()}
            </div>

            <div className="item-sidebar-description" dangerouslySetInnerHTML={createMarkup()}/>

        </div>
    </Modal>
}

export default ItemSidebar;