import React from 'react';
import './chart.scss'
import cn from 'classnames';


const Chart = (props: any) => {
    return <svg className={cn("chart-default", {[props.className]: !!props.className})} viewBox="0 0 41 41" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="desktop_1440" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M20.2115305,22.6603616 L34.2961882,35.3322725 C30.7215107,38.6875463 25.9374362,40.7852613 20.6503382,40.7852613 C12.0668309,40.7852613 4.80509818,35.3483265 1.9689019,27.7548123 L1.9689019,27.7548123 L20.2115305,22.6603616 Z M27.8050447,2.15626784 C35.3075865,5.04597726 40.6481977,12.2648995 40.6481977,20.784191 C40.6481977,25.3221051 39.0802627,29.4533193 36.5383887,32.808593 L36.5383887,32.808593 L22.5661085,20.238357 Z M20.653549,0.785261257 C21.9967287,0.785261257 23.3078006,0.924395414 24.576062,1.18125847 L24.576062,1.18125847 L19.2943153,19.4131844 L1.03028146,24.5129864 C0.800174973,23.3035895 0.650338188,22.0567334 0.650338188,20.7831207 C0.650338188,9.73800916 9.60308609,0.785261257 20.653549,0.785261257 Z" id="chart_ico" />
        </g>
    </svg>
};

export default Chart;