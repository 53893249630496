import React, {useState} from 'react';
import Check from "../../../SVG/Check/Check";
import getTranslation from '../../../../helpers/getTranslation';
import cn from 'classnames';

type TJoinCard = {
    title: string,
    text: string,
    placeholder: string,
    onSave: (email: string) => void;
}

type errors = {
    agree: boolean,
    email: boolean
};

const EMAIL_VALIDATION_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const JoinCard = (props: TJoinCard) => {
    const [hasAgree, setHasAgree] = useState<boolean>(false);
    const [userEmail, setUserEmail] = useState<string>('');
    const [errors, setErrors] = useState<errors>({
        agree: false,
        email: false
    });

    const agreeWithTerms = () => {
        setHasAgree((prev) => !prev);
    };
    const onSave = () => {
        const agree = !hasAgree;
        const validEmail = validateEmail(userEmail);
        // @ts-ignore
        const email = !(Boolean(validEmail) && Boolean(validEmail.length));
        const  isValid = !agree && !email;
        if (isValid) {
            props.onSave(userEmail);
        } else {
            // @ts-ignore
            setErrors({agree ,email});
        }
    };
    const validateEmail = (email: string) => {
        return String(email)
            .toLowerCase()
            .match(EMAIL_VALIDATION_PATTERN);
    };
    const onEmailChange = (ev: any) => {
        setUserEmail(ev.target.value);
    };

    return <div className="generate-card">
        <h2 className="join-card-title ">{props.title}</h2>
        <p className="join-card-text">{props.text}</p>
        <input type="email"
               onChange={onEmailChange}
               className={cn('join-card-input', {'error': errors.email})}
               placeholder={props.placeholder}
               value={userEmail}/>
        <button className={'btn btn_green btn-big play-btn join-btn'}
                onClick={onSave}>{getTranslation('joinButtonText')}</button>
        <div className="join-card-footer">
            <figure onClick={agreeWithTerms} className={cn({'error': errors.agree})}>
                {hasAgree && <Check className="check-icon"/>}
            </figure>
            <a className="terms-link" target="_blanc"
               href={getTranslation('joinTerms')}>{getTranslation('joinTerms')}</a>
        </div>
    </div>
};

export default JoinCard;