import React, {FC} from 'react';
import cn from 'classnames';

type TRatingItemProps = {
    name: string;
    value: number;
    onClick: () => void;
    className?: string
}

const RatingItem: FC<TRatingItemProps> = (props: TRatingItemProps): JSX.Element => {

    const {value} = props;

    let className = cn(props.className ? props.className : '', {
        'green': value === 3,
        'yellow': value === 2,
        'pink': value === 1
    })
    return <div className={cn("rating-item slide-down", className)} onClick={props.onClick}>
        <div className="rating-item-name">
            {props.name}
        </div>
        <div className="rating-item-label"/>
    </div>
};

export default RatingItem;