import React from 'react';
import './check.scss';

const cn = require('classnames');
const Check = (props: any) => {

    return <svg className={cn("check-default", {[props.className]: props.className})} viewBox="0 0 41 41" version="1.1"
                xmlns="http://www.w3.org/2000/svg">
        <title>check_ico</title>
        <g id="desktop_1440" stroke="none" strokeWidth="1" fillRule="evenodd">
            <path
                d="M20.9777507,0.785261257 C12.0326677,8.46342218 0.972642107,13.1428602 0.972642107,24.0547376 C0.972642107,30.2667427 6.18847863,34.3076112 11.2306242,32.5962447 L16.4771121,30.7980326 L11.5933318,39.1965001 C11.5933318,39.1965001 14.8679167,40.7852613 20.9777507,40.7852613 C27.0875846,40.7852613 30.357061,39.1965001 30.357061,39.1965001 L25.4783892,30.7980326 L30.7248771,32.5962447 C35.7619141,34.3076112 40.9777507,30.2667427 40.9777507,24.0547376 C40.9777507,13.1428602 29.9228337,8.46342218 20.9777507,0.785261257"
                id="check_ico" />
        </g>
    </svg>
};

export default Check;
