import React from 'react';
import './copyAndTerms.scss';
//@ts-ignore
import terms_en from '../../files/terms_en.pdf';
//@ts-ignore
import terms_se from '../../files/terms_se.pdf';
//@ts-ignore
import terms_fi from '../../files/terms_fi.pdf';
import getTranslation from '../../helpers/getTranslation';
import AppStore from '../../AppStore';
import {language} from '../Views/Home/constants';


const CopyAndTerms = () => {

    const date: Date = new Date();
    const lng = AppStore.locale;

    let terms;

    switch (lng) {
        case language.SWEDISH:
            terms = terms_se

            break;
        case language.FINLAND:
            terms = terms_fi

            break;
        default:
            terms = terms_en;
    }

    return <div className="copy-and-terms slide-down">
        <span>&copy;{` ${date.getFullYear()} `}</span>
        <a href={terms} target="_blank" rel="noopener noreferrer">{getTranslation('privacyTerms')}</a>
    </div>
};

export default CopyAndTerms;