import React, {useEffect} from 'react';
import './App.scss';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Rating from './Components/Views/Rating';
import Results from './Components/Views/Results';
import Home from './Components/Views/Home/Home';
import getLanguagesApi from './api/getLanguagesApi';
import {observer} from 'mobx-react';
import AppStore from './AppStore';
import getTranslationsApi from "./api/getTranslationsApi";
import CookieBar from './Components/CookieBar/CookieBar';

function App() {

    useEffect(() => {

        Promise.all([getLanguagesApi(), getTranslationsApi(AppStore.locale)]).then((resp) => {
            const [languages, translations] = resp;
            //@ts-ignore
            AppStore.setLanguages(languages && languages.data ? languages.data : {});
            //@ts-ignore
            window.translations = translations && translations.data ? translations.data : {};
            AppStore.setLoading(false);
        }, (error) => {
            AppStore.setLoading(false);
            console.log(error);
        });

    }, []);

    if (AppStore.loading) {
        return null;
    }
    return <div className="App">
        <Router>
            <Switch>
                <Route path='/:lng?/rating/:id' component={Rating}/>
                <Route path='/:lng?/results' component={Results}/>
                <Route path='/:lng?' component={Home}/>
            </Switch>
        </Router>
        <CookieBar />
    </div>;
}

export default observer(App);
