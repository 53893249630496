import React, {useEffect} from 'react';
import './modal.scss';

const cn =require('classnames');
const Modal = (props: any) => {
    useEffect(() => {
        document.body.classList.add('modal-open');

        return () => { document.body.classList.remove('modal-open');}
    });

    return <div className={cn("modal-overlay", {[props.className]: props.className})}>
        {props.children}
    </div>
};

export default Modal;

