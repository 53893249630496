import React from 'react';
import   './card.scss';

type TCard = {
    iconClass?: string,
    text: string,
    icon?: any;
}

const Card = (props: TCard) => {

    return <div className={"card-container"}>
        {props.icon ? props.icon : <div className={props.iconClass}>{null}</div> }
        <p className={"card-text slide-down"}>{props.text}</p>
    </div>
}

export default Card;