import React from 'react';
import './arrow.scss';

const cn=require('classnames');
const Arrow = (props:any) => {

    return <svg className={cn('arrow-default', {[props.className]: props.className})} viewBox="0 0 18 101" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>arrow_ico</title>
        <g id="desktop_1440" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M-31.9870673,41.7852613 L-31.9943929,41.7852613 C-32.2022313,41.7870889 -32.3954209,41.8693225 -32.5400841,42.0182557 L-40.7721509,50.2342969 C-40.9241386,50.3804893 -41.007458,50.5751089 -41.007458,50.7843481 C-41.007458,50.9945005 -40.9241386,51.1900333 -40.7730666,51.3353125 L-32.5419155,59.5513525 C-32.3963366,59.7012001 -32.203147,59.7834337 -31.9953086,59.7852613 C-31.9925615,59.7852613 -31.9898144,59.7852613 -31.9870673,59.7852613 C-31.7746516,59.7852613 -31.5750521,59.7021145 -31.4230645,59.5513525 C-31.1200049,59.2480021 -31.1209194,58.7472913 -31.4248959,58.4329765 L-38.3128774,51.5719621 L58.2106292,51.5719621 C58.6418718,51.5719621 58.992542,51.2156161 58.992542,50.7788653 C58.992542,50.3421145 58.6491962,49.9985605 58.2106292,49.9976473 L-38.3128774,49.9976473 L-31.4230645,43.1229265 C-31.2774856,42.9776473 -31.1969144,42.7821145 -31.1969144,42.5719621 C-31.1969144,42.3618097 -31.2774856,42.1653625 -31.4230645,42.0200833 C-31.5750521,41.8684081 -31.7746516,41.7852613 -31.9870673,41.7852613 Z" id="arrow_ico" fillRule="nonzero" transform="translate(8.992542, 50.785261) scale(-1, 1) rotate(-90.000000) translate(-8.992542, -50.785261) "/>
        </g>
    </svg>
}

export default Arrow;