import React, {useEffect} from 'react';
import './home.scss';
import {homeLayout, layoutDirection} from './constants';
import Winning from './Wiinnig';
import Cards from './Cards';
import Basic from './Basic';
//@ts-ignore
import DocumentMeta from 'react-document-meta';


const cn = require('classnames');

const Home = () => {

    let layout: string | null = localStorage.getItem('insaider-layout')
    let layoutNumber: any = layout ? parseInt(layout) : homeLayout.FIRST;
    let direction = layoutDirection.VERTICAL;
    if (layoutNumber === homeLayout.SECOND || layoutNumber === homeLayout.FOURTH) {
        direction = layoutDirection.HORIZONTAL;
    }

    const containerClass = cn(
        {'home-container_first': layoutNumber === homeLayout.FIRST},
        {'home-container_second': layoutNumber === homeLayout.SECOND},
        {'home-container_third': layoutNumber === homeLayout.THIRD},
        {'home-container_fourth': layoutNumber === homeLayout.FOURTH}
    );

    const meta = {
        title: 'Insaider - your online casino winning algorithm!',
        description: 'Insaider is your technology partner among virtual gambling clubs looking for casino bonuses based on insider information using an artificial intelligence system in the selection and analysis of big data.',
    }

    useEffect(() => {
        if (layoutNumber === homeLayout.FOURTH) {
            localStorage.setItem('insaider-layout', homeLayout.FIRST.toString());
        } else {
            localStorage.setItem('insaider-layout', (layoutNumber + 1).toString());

        }
    }, [layoutNumber]);

    return <div className="container">
        <DocumentMeta {...meta} />
        <div className={cn('home-container', containerClass)}>
            <Basic direction={direction}/>
            <Winning/>
            <Cards/>
        </div>
    </div>
}

export default Home;