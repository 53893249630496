import {TFilterItem} from '../Components/Views/Results/FilterModal/FiltersVM';

export default function getFiltersIds (filters:TFilterItem[]) {
    const res: number[] = [];

    filters.forEach((item: TFilterItem) => {
        if (item.selected) {
            res.push(item.id);
        }
    });


    return  JSON.stringify(res);
}