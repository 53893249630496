import React from 'react';
import './mark.scss';

const cn = require('classnames');
const Mark = (props: any) => {
    return <svg className={cn('mark-default', {[props.className]: props.className})} viewBox="0 0 55 61" version="1.1"
                xmlns="http://www.w3.org/2000/svg">
        <title>mark_ico</title>
        <g id="desktop_1440" stroke="none" strokeWidth="1" fillRule="evenodd">
            <path
                d="M19.4879497,20.3538459 L21.1631091,43.4955983 L33.6711146,43.4955983 L35.3485016,20.3538459 L38.2867134,20.3538459 L54.0848923,54.3845386 L38.6854548,60.7852613 L35.3485016,53.9240391 L19.4879497,53.9240391 L16.1509965,60.7852613 L0.751558985,54.3845386 L16.5475103,20.3538459 L19.4879497,20.3538459 Z M27.4175574,0.785261257 C31.79703,0.785261257 35.3456057,4.29739619 35.3456057,8.62917616 C35.3456057,12.9609561 31.79703,16.4708877 27.4175574,16.4708877 C23.0403124,16.4708877 19.489509,12.9609561 19.489509,8.62917616 C19.489509,4.29739619 23.0403124,0.785261257 27.4175574,0.785261257 Z"
                id="mark_ico"/>
        </g>
    </svg>
}

export default Mark;